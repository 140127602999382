<template>
  <el-dialog v-model="dialogFormVisible" :before-close="handleClose" :close-on-click-modal="false" :title="title" width="909px">
    <el-tabs v-model="activeName" type="card" class="demo-tabs" @tab-click="handleClick">
      <el-tab-pane label="本地上传" name="first">
        <div class="upload">
          <el-alert :closable="false" :title="`支持mp4,ogg,flv,avi,wmv,rmvb,mov格式，可最多选择1条视频，每不可大于200M，如果大于200M会自动为您过滤`" type="info" />
          <el-upload ref="upload" action="#" :auto-upload="false" class="upload-content" :close-on-click-modal="false" :data="data" :file-list="fileList" :headers="headers" :limit="limit" list-type="text" :multiple="true" :name="name" :on-change="handleChange" :on-error="handleError"
            :on-exceed="handleExceed" :on-preview="handlePreview" :on-progress="handleProgress" :on-remove="handleRemove" :on-success="handleSuccess">
            <el-button type="primary">点击上传视频</el-button>
          </el-upload>
        </div>

      </el-tab-pane>
      <el-tab-pane label="视频内容库" name="second">
        <el-table border :data="list">
          <el-table-column label="标题" prop="title" show-overflow-tooltip align="center" />
          <el-table-column label="封面">
            <template #default="{ row }">
              <el-image style="width: 100px; height: 100px" :src="row.cover" :preview-src-list="[row.cover]" fit="cover" />
            </template>
          </el-table-column>
          <el-table-column label="视频">
            <template #default="{ row }">
              <video :src="row.fileUrl" controls style="width:150px;height:150px;"></video>
            </template>
          </el-table-column>
          <el-table-column label="标签" prop="name">
            <template #default="{ row }">
              <el-tag v-for="(item, index) in row.materialTagVos" :key="index" type='success'>{{item.tagName}}</el-tag>
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template #default="{ row }">
              <el-button @click="chooseImg(row)">上传</el-button>
            </template>
          </el-table-column>
          <template #empty>
            <el-empty class="vab-data-empty" description="暂无数据" />
          </template>
        </el-table>
        <el-pagination background :current-page="queryForm.pageNumber" :layout="layout" :page-size="queryForm.pageSize" :total="total" @current-change="handleCurrentChange" @size-change="handleSizeChange" />

      </el-tab-pane>
    </el-tabs>
    <template #footer >
      <div style="position: relative; padding-right: 15px; text-align: right" v-if="activeName == 'first'">
        <div v-if="show" style="position: absolute; top: 10px; left: 15px; color: #999">
          正在上传中... 当前上传成功数:{{ imgSuccessNum }}张 当前上传失败数:{{
            imgErrorNum
          }}张
        </div>
        <el-button type="primary" @click="handleClose">关闭</el-button>
        <el-button :loading="loading" size="small" style="margin-left: 10px" type="success" @click="submitUpload">
          开始上传
        </el-button>
      </div>
    </template>
  </el-dialog>
</template>

<script>
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'
import _ from 'lodash'
import { upLoadImg } from '@/api/active'
import {
  enterprisequeryMaterial,
  enterpriseQueryMaterialTag,
} from '@/api/content'
export default defineComponent({
  name: 'VabUpload',
  props: {
    url: {
      type: String,
      default: '/upload',
      required: true,
    },
    name: {
      type: String,
      default: 'file',
      required: true,
    },
    limit: {
      type: Number,
      default: 50,
      required: true,
    },
    size: {
      type: Number,
      default: 1,
      required: true,
    },
  },
  data() {
    return {
      show: false,
      loading: false,
      dialogVisible: false,
      dialogImageUrl: '',
      action: 'https://vab-unicloud-3a9da9.service.tcloudbase.com/upload',
      headers: {},
      fileList: [],
      picture: 'picture',
      imgNum: 0,
      imgSuccessNum: 0,
      imgErrorNum: 0,
      typeList: null,
      title: '视频上传',
      dialogFormVisible: false,
      data: {},
      staticImg: [],
      layout: 'prev, pager, next',
      activeName: 'first',
      queryForm: {
        likeTitle: '',
        pageNum: 1,
        pageSize: 10,
        tagIds: '',
        type: 'Video',
      },
      querySelectForm: { pageNum: 1, pageSize: 999 },
      list: [],
      tagList: [],
      total: 0,
    }
  },
  computed: {
    ...mapGetters({
      token: 'user/token',
    }),
    percentage() {
      if (this.allImgNum === 0) return 0
      return _.round(this.imgNum / this.allImgNum, 2) * 100
    },
  },
  created() {
    this.headers['Authorization'] = `Bearer ${this.token}`
  },
  methods: {
    async fetchData() {
      const { data } = await enterprisequeryMaterial(this.queryForm)
      const { data: tagList } = await enterpriseQueryMaterialTag(
        this.querySelectForm
      )
      this.list = data.list
      this.total = data.total

      this.tagList = tagList.list
    },
    async submitUpload() {
      console.log(this.fileList)
      let arr = []
      let upData = new FormData()
      for (let i in this.fileList) {
        upData.append('file', this.fileList[i].raw)
      }
      const { data } = await upLoadImg(upData)
      console.log(data)
      this.$emit('addVideo', data)
      this.handleClose()
    },
    handleProgress() {
      this.loading = true
      this.show = true
    },
    beforeUploadVideo(file) {
      console.log(file)
      const fileSize = file.size / 1024 / 1024 < 200

      if (!fileSize) {
        this.$message.error('视频大小不能超过200MB')

        return false
      }
    },

    handleChange(file, fileList) {
      const fileSize = file.size / 1024 / 1024 < 200
      console.log(file, file.raw.type)
      if (!fileSize) {
        this.$message.error('视频大小不能超过200MB')
        this.fileList = []
      } else if (
        [
          'video/mp4',
          'video/ogg',
          'video/flv',
          'video/avi',
          'video/wmv',
          'video/rmvb',
          'video/mov',
        ].indexOf(file.raw.type) === -1
      ) {
        this.$message.error('请上传正确的视频格式')
        this.fileList = []
      } else {
        this.allImgNum = fileList.length
        this.fileList = fileList
      }
    },
    handleSuccess(response, file, fileList) {
      this.imgNum = this.imgNum + 1
      this.imgSuccessNum = this.imgSuccessNum + 1
      if (fileList.length === this.imgNum) {
        setTimeout(() => {
          this.$baseMessage(
            `上传完成! 共上传${fileList.length}张图片`,
            'success',
            'vab-hey-message-success'
          )
        }, 1000)
      }

      setTimeout(() => {
        this.loading = false
        this.show = false
      }, 1000)
    },
    handleError(err, file) {
      this.imgNum = this.imgNum + 1
      this.imgErrorNum = this.imgErrorNum + 1
      this.$baseMessage(
        `文件[${file.raw.name}]上传失败,文件大小为${_.round(
          file.raw.size / 1024,
          0
        )}KB`,
        'error',
        'vab-hey-message-error'
      )
      setTimeout(() => {
        this.loading = false
        this.show = false
      }, 1000)
    },
    handleRemove() {
      this.imgNum = this.imgNum - 1
      this.allNum = this.allNum - 1
    },
    handlePreview(file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    handleExceed(files) {
      this.$baseMessage(
        `当前限制选择 ${this.limit} 个文件，本次选择了
             ${files.length}
             个文件`,
        'error',
        'vab-hey-message-error'
      )
    },
    handleShow(data) {
      this.title = '视频上传'
      this.data = data
      this.fetchData()
      this.dialogFormVisible = true
    },
    handleClose() {
      this.fileList = []
      this.picture = 'picture'
      this.allImgNum = 0
      this.imgNum = 0
      this.imgSuccessNum = 0
      this.imgErrorNum = 0
      this.headers['Authorization'] = `Bearer ${this.token}`
      this.dialogFormVisible = false
    },
    chooseImg(data) {
      this.$emit('addVideo', data.fileUrl)
      this.handleClose()
    },
  },
})
</script>

<style lang="scss" scoped>
.upload {
  height: 500px;

  .upload-content {
    // .el-upload__tip {
    //   display: block;
    //   height: 30px;
    //   line-height: 30px;
    // }

    // :deep() {
    //   .el-upload--picture-card {
    //     width: 128px;
    //     height: 128px;
    //     margin: 3px 8px 8px 8px;
    //     border: 2px dashed #c0ccda;
    //   }

    //   .el-upload-list--picture {
    //     margin-bottom: 20px;
    //   }

    //   .el-upload-list--picture-card {
    //     .el-upload-list__item {
    //       width: 128px;
    //       height: 128px;
    //       margin: 3px 8px 8px 8px;
    //     }
    //   }
    // }
  }
}
</style>
